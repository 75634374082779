import './App.css';
import OrderQuery from './pages/Order/index'

function App() {
  return (
    <OrderQuery />
  );
}

export default App;
